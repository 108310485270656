<template>

  <h1>test archivos</h1>
</template>

<script>

import i18n from '../plugins/il8n';

export default {
  name: 'test',
  data() {
    return {
      test: 'test',
    };
  },
  methods: {
    changeLanguage(locale) {
      i18n.locale = locale;
    },
  },
};
</script>
